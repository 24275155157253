<template>
	<v-card flat tile>
		<v-card-text class="pt-1">
			<!-- Tabs -->
			<v-card class="elevation-4" tile>
				<v-toolbar flat>
					<v-tabs v-model="current_tab" fixed-tabs slider-color="wr_blue_1">
						<v-tab
							:key="tab.id"
							v-for="tab in tabs"
							:href="'#' + tab.link"
							:class="`${tab.color} wr_blue_1--text font-weight-bold`"
						>
							<!-- Else tabs will have translated names (If any) -->
							<span :class="{ 'text-truncate': tab.origin == 'private' }">
								<span v-if="tab.origin == 'private'" class="caption"
									>{{
										$t("app.chapter_param", {
											chapter_count: tab.chapter_count,
										})
									}}<br />
								</span>
								<span :class="{ 'text-truncate': tab.origin == 'private' }">
									{{ $te(tab.title) ? $t(tab.title) : tab.title }}
								</span>
							</span>
						</v-tab>
					</v-tabs>
				</v-toolbar>

				<v-tabs-items v-model="current_tab">
					<v-tab-item :key="tab.id" v-for="tab in tabs" :value="tab.link">
						<!-- If content is not loaded yet -->

						<v-card-text
							v-if="
								tab.component !== 'TableOfContent' &&
									tab.section_component !== 'ResearchPoster' &&
									!setup_done
							"
						>
							<Loading></Loading>
						</v-card-text>
						<!-- When content is ready -->
						<component
							v-if="
								tab.component == 'TableOfContent' ||
									tab.section_component == 'ResearchPoster' ||
									(tab.component !== 'TableOfContent' &&
										tab.section_component !== 'ResearchPoster' &&
										setup_done)
							"
							:is="tab.component"
							:section_component="tab.section_component"
							:chapter_id="tab.id"
						></component>
					</v-tab-item>
				</v-tabs-items>
			</v-card>
		</v-card-text>

		<!-- Show finish source documentation  -->
		<StageFinishDialog
			v-if="dialog"
			v-model="dialog"
			:dialog="dialog"
			current_step="app.research_paper.title"
			next_step="app.write_research.summary.title"
			@close="dialog = false"
		></StageFinishDialog>
	</v-card>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import * as tabComponents from "@/components/research/modules/write/stages/researchPaper/tabs/index";
import StageFinishDialog from "@/components/research/modules/_common/stages/FinishDialog";
import {
	filterArray,
	generateRandomString,
	isArrayHavingItem,
} from "@/utils/helpers";
import moment from "moment";
import { researchPaperTabsMixin } from "@/mixins/researchPaper/tabsMixin.js";
import { referenceManagerMixin } from "@/mixins/researchPaper/referenceManagerMixin.js";
import { researchPaperPadManagerMixin } from "@/mixins/researchPaper/padManagerMixin.js";
import Loading from "@/components/plugins/Loading.vue";
import { mapFields } from "vuex-map-fields";

export default {
	name: "xl9baq_PosterPage",

	mixins: [
		researchPaperTabsMixin,
		referenceManagerMixin,
		researchPaperPadManagerMixin,
	],

	data() {
		return {
			moment,
			dialog: false,
		};
	},

	components: {
		...tabComponents,
		StageFinishDialog,
		Loading,
	},

	computed: {
		...mapFields("researchPaper", {
			current_tab: "current_tab",
		}),

		...mapState({
			template: (state) => state.researchPaper.template,
			sources: (state) => state.source.list,
		}),

		...mapGetters({
			userProfile: "user/getProfile",
		}),

		templateLength() {
			return this.template.length;
		},
	},

	watch: {
		templateLength: {
			handler(newVal) {
				if (newVal) {
					this.init();
				}
			},
			immediate: true,
		},
	},

	created() {
		this.getTemplate();
		this.readyToListenEvents();
	},

	methods: {
		readyToListenEvents() {
			this.$eventBus.$on("update-tab", (payload) => {
				this.current_tab = payload;
			});
			this.$eventBus.$on("redirect-from-xl9baq_poster", (payload) => {
				this.dialog = true;
			});
		},

		async getTemplate() {
			try {
				this.$loader.start();

				var response = await this.$store.dispatch("researchPaper/template", {
					clear: false,
				});

				/**
				 * initial template needs to be requested when clear is true
				 * -- can't do it every time as it will erase the content of template ---
				 */
				if (!response.length) {
					this.$store.dispatch("researchPaper/template", {
						clear: true,
					});
				}
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async init() {
			try {
				this.$loader.start();

				// Prepare pad for chapters if not exists.
				await this.manageChaptersAndRespectivePads();

				// get all sources
				await this.$store.dispatch("source/list");

				// Prepare reference from sources
				let response = await this.prepareReferenceFormat(this.sources);

				// Save to etherpad (even if empty array)
				await this.$store.dispatch("etherpad/saveReferences", {
					user_id: this.userProfile.id,
					data: response,
				});
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},

	beforeDestroy() {
		this.$eventBus.$off("update-tab");
		this.$eventBus.$off("redirect-from-xl9baq_poster");
	},
};
</script>
