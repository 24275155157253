import { mapGetters } from "vuex";
import ShortUniqueId from "short-unique-id";
const uid = new ShortUniqueId({ length: 10 });

export const researchPaperPadManagerMixin = {
	data() {
		return {
			setup_done: false,
		};
	},

	computed: {
		...mapGetters({
			userProfile: "user/getProfile",
		}),
	},

	methods: {
		async createUuidIfNotExists(chapter_id, chapter_uuid) {
			// if exists
			if (chapter_uuid && chapter_uuid.includes("-")) return chapter_uuid;

			// if not exists
			let uuid = `${this.userProfile.id}-${uid()}`;
			await this.$store.dispatch("researchPaper/setTemplateUUID", {
				id: chapter_id,
				uuid: uuid,
			});

			return uuid;
		},

		async createPadIfNotExists(pad_id) {
			const exists = await this.$store.dispatch("etherpad/doesPadExists", {
				pad_id,
			});
			// If not exists then create
			if (!exists) {
				await this.$store.dispatch("etherpad/createPad", {
					pad_id,
					text: this.$t("app.start_editing_here"),
				});
			}
		},

		async manageChaptersAndRespectivePads() {
			try {
				this.$loader.start();

				for (const item of this.template) {
					// 1. create uuid if not exists
					let uuid = await this.createUuidIfNotExists(item.id, item.uuid);

					// 2. check if pad of that uuid exists or not
					await this.createPadIfNotExists(uuid);
				}

				this.setup_done = true;
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},
};
