<template>
	<div>
		<v-expansion-panels v-model="dialog" flat>
			<v-expansion-panel>
				<v-expansion-panel-header class="px-2">
					<template v-slot:default>
						<div>
							<v-icon>
								{{
									dialog == null
										? $vuetify.icons.values[
												$vuetify.rtl ? "menu_left" : "menu_right"
										  ]
										: $vuetify.icons.values.menu_down
								}}
							</v-icon>
							<span class="headline wr_blue_1--text">
								{{ $t("app.dialog") }}
							</span>
						</div>
					</template>
					<template v-slot:actions>
						<span></span>
					</template>
				</v-expansion-panel-header>
				<v-expansion-panel-content>
					<small class="pl-4 font-italic">
						{{ $t("app.research_paper.recommend_to_mark_response") }}
					</small>
					<v-data-table
						v-model="selected"
						:headers="headers"
						:items="suggestions"
						single-select
						item-key="name"
						show-select
						class="elevation-1 mt-4"
					>
						<template v-slot:item.comments="{ item }">
							<v-textarea
								class="mt-4"
								dense
								outlined
								v-model="item.comments"
								rows="3"
								color="greenAccent2"
								background-color="white"
								:placeholder="
									$t('app.placeholder.write_to_improve_writing_task')
								"
							>
								<span slot="append" class="me-2">
									<SpeechToText
										:text="item.comments"
										@speechend="item.comments = $event.text"
									></SpeechToText>
								</span>
								<TextToSpeech
									slot="append"
									:text="item.comments"
								></TextToSpeech>
							</v-textarea>
						</template>
					</v-data-table>
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>
		<div id="suggestion"></div>
	</div>
</template>
<script>
import SpeechToText from "@/components/plugins/SpeechToText";
import TextToSpeech from "@/components/plugins/TextToSpeech.vue";

export default {
	name: "ResearchPaperMentorSuggestion",

	components: {
		SpeechToText,
		TextToSpeech,
	},

	data() {
		return {
			selected: [],
			dialog: null,
			headers: [
				{
					text: this.$t("app.s_no"),
					align: "start",
					sortable: false,
					value: "id",
					width: "5%",
				},
				{ text: this.$t("app.date"), value: "date", width: "20%" },
				{
					text: this.$t("app.suggestions_and_feedback"),
					value: "comments",
					width: "75%",
				},
			],
			suggestions: [
				{
					id: 1,
					date: "12/12/2020 11:00 AM",
					comments: "",
				},
				{
					id: 2,
					date: "12/12/2020 11:00 AM",
					comments: "",
				},
			],
		};
	},
};
</script>
