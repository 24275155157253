var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":"","tile":""}},[_c('v-card-text',{staticClass:"pt-1"},[_c('v-card',{staticClass:"elevation-4",attrs:{"tile":""}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-tabs',{attrs:{"fixed-tabs":"","slider-color":"wr_blue_1"},model:{value:(_vm.current_tab),callback:function ($$v) {_vm.current_tab=$$v},expression:"current_tab"}},_vm._l((_vm.tabs),function(tab){return _c('v-tab',{key:tab.id,class:((tab.color) + " wr_blue_1--text font-weight-bold"),attrs:{"href":'#' + tab.link}},[_c('span',{class:{ 'text-truncate': tab.origin == 'private' }},[(tab.origin == 'private')?_c('span',{staticClass:"caption"},[_vm._v(_vm._s(_vm.$t("app.chapter_param", { chapter_count: tab.chapter_count, }))),_c('br')]):_vm._e(),_c('span',{class:{ 'text-truncate': tab.origin == 'private' }},[_vm._v(" "+_vm._s(_vm.$te(tab.title) ? _vm.$t(tab.title) : tab.title)+" ")])])])}),1)],1),_c('v-tabs-items',{model:{value:(_vm.current_tab),callback:function ($$v) {_vm.current_tab=$$v},expression:"current_tab"}},_vm._l((_vm.tabs),function(tab){return _c('v-tab-item',{key:tab.id,attrs:{"value":tab.link}},[(
							tab.component !== 'TableOfContent' &&
								tab.section_component !== 'ResearchPoster' &&
								!_vm.setup_done
						)?_c('v-card-text',[_c('Loading')],1):_vm._e(),(
							tab.component == 'TableOfContent' ||
								tab.section_component == 'ResearchPoster' ||
								(tab.component !== 'TableOfContent' &&
									tab.section_component !== 'ResearchPoster' &&
									_vm.setup_done)
						)?_c(tab.component,{tag:"component",attrs:{"section_component":tab.section_component,"chapter_id":tab.id}}):_vm._e()],1)}),1)],1)],1),(_vm.dialog)?_c('StageFinishDialog',{attrs:{"dialog":_vm.dialog,"current_step":"app.research_paper.title","next_step":"app.write_research.summary.title"},on:{"close":function($event){_vm.dialog = false}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }