<template>
	<section>
		<h2>{{ chapter.num }} {{ chapter.title }}</h2>
		<ol>
			<li v-for="(item, index) in chapter.references" :key="index">
				{{ item.text }}
			</li>
		</ol>
	</section>
</template>

<script>
export default {
	name: "ResearchPaperBibliographyPreview",

	props: {
		chapter: {
			required: true,
		},
	},
};
</script>

<style></style>
