<template>
	<section id="toc" :style="styleObj">
		<h2>{{ $t("app.table_of_content") }}</h2>
		<ol>
			<template v-for="(chapter, index) in prop_data">
				<li :key="index">
					{{ chapter.title }} {{ chapter.chapter_type }}
					<ol v-if="chapter.children">
						<li v-for="subChapter in chapter.children" :key="subChapter.id">
							{{ subChapter.title }}
						</li>
					</ol>
				</li>
			</template>
		</ol>
	</section>
</template>

<script>
export default {
	name: "ResearchPaperTOCPreview",

	props: {
		styleObj: {},
		prop_data: {
			required: true,
		},
	},
};
</script>

<style></style>
