<template>
	<section id="home-page" :style="styleObj">
		<h1>
			{{ research_question }}
		</h1>
		<p>
			<i>{{ $t("app.submitted_by") }}</i>
		</p>
		<p style="font-size:30px;margin-top:10px">
			<b>{{ submitter }}</b>
		</p>
		<p style="margin-top:40px">
			<i>{{ $t("app.word_count") }}</i>
		</p>
		<p>
			<b style="margin-top:10px">
				<!-- total words will assign dynamically -->
				<span id="total-words"></span>
				{{ this.$t("app.words") }}
				({{ this.$t("app.excluding_toc") }}).
			</b>
		</p>
	</section>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
	name: "ResearchPaperIntroPreview",

	props: {
		styleObj: {},
		research_question: {
			required: true,
		},
	},

	computed: {
		...mapState({
			current_student: (state) => state.student.current_student,
		}),

		...mapGetters({
			isMentor: "user/isMentor",
			fullName: "user/fullName",
		}),

		submitter() {
			return this.isMentor
				? this.fullName(this.current_student.user)
				: this.fullName();
		},
	},
};
</script>

<style></style>
