<template>
	<v-dialog :value="dialog" scrollable max-width="550px" persistent>
		<AppDialog
			v-model="loading"
			:heading="{
				label: toEditChapter
					? $t('app.edit_chapter')
					: toAddChapter
					? $t('app.add_chapter')
					: $t('app.add_subchapter'),
			}"
			:action="{
				label: 'app.save',
				event: 'save',
			}"
			@close="$emit('close')"
			@save="submit()"
		>
			<template v-slot:dialog-content>
				<div v-if="toAddSubChapter">
					<b>{{ prop_chapter.parent.title }}</b>
					> {{ $t("app.new_subchapter") }}
				</div>

				<v-form ref="form" class="mt-9">
					<v-text-field
						v-model="form.title"
						:rules="[rules.required]"
						color="greenAccent2"
						background-color="white"
						outlined
						:label="$t('app.title')"
					></v-text-field>

					<v-textarea
						v-model="form.content"
						:rules="[rules.required]"
						color="greenAccent2"
						background-color="white"
						outlined
						:label="$t('app.write_sentences_about_content')"
					></v-textarea>

					<v-row v-if="toAddChapter || toAddSubChapter">
						<v-col>
							<v-select
								v-if="suitableChapters && suitableChapters.length"
								v-model="after"
								:rules="[rules.required]"
								:items="suitableChapters"
								item-text="title"
								:item-value="toAddChapter ? 'level' : 'sub_level'"
								:placeholder="$t('app.move_after')"
								outlined
								dense
								color="greenAccent2"
								background-color="white"
							>
							</v-select>
						</v-col>
					</v-row>
				</v-form>
			</template>
		</AppDialog>
	</v-dialog>
</template>

<script>
import rules from "@/utils/validation";
import { mapGetters, mapState } from "vuex";
import AppDialog from "@/components/ui/AppDialog.vue";
import { findFromArray, findElIndex } from "@/utils/helpers";
import ShortUniqueId from "short-unique-id";
const uid = new ShortUniqueId({ length: 10 });

export default {
	name: "PopulateChapterDialog",

	props: {
		dialog: {
			required: true,
			type: Boolean,
		},
		prop_chapter: {
			required: true,
			type: Object,
		},
	},

	data() {
		return {
			rules,
			loading: false,
			after: null,
			before: null,
			form: {
				title: "",
				content: "",
				level: null,
				sub_level: 0,
			},
		};
	},

	components: {
		AppDialog,
	},

	computed: {
		...mapState({
			researchTemplate: (state) => state.researchPaper.template,
		}),
		...mapGetters({
			privateChapterCount: "researchPaper/getPrivateChapterCount",
			userProfile: "user/getProfile",
		}),

		isPropChapEmpty() {
			return !Object.keys(this.prop_chapter).length;
		},

		toAddChapter() {
			return this.isPropChapEmpty;
		},

		toAddSubChapter() {
			return !this.isPropChapEmpty && this.prop_chapter.parent;
		},

		toEditChapter() {
			return !this.isPropChapEmpty && !this.prop_chapter.parent;
		},

		rootLevelChapters() {
			return this.researchTemplate.filter(
				(item) => item.level && !item.sub_level,
			);
		},

		subChapters() {
			return this.researchTemplate.filter((item) => {
				return item.sub_level && item.level == this.prop_chapter.parent.level;
			});
		},

		suitableChapters() {
			return this.toAddChapter ? this.rootLevelChapters : this.subChapters;
		},

		suitableKey() {
			return this.toAddChapter ? "level" : "sub_level";
		},
	},

	mounted() {
		this.init();
	},

	methods: {
		init() {
			// If request is to add subchapter
			if (this.toAddSubChapter) {
				this.form.level = this.prop_chapter.parent.level;
			}
			// If request is to edit chapter/subchapter
			if (this.toEditChapter) {
				Object.assign(this.form, this.prop_chapter);
			}
		},

		async setUuidForCreatedChapter(id) {
			let uuid = `${this.userProfile.id}-${uid()}`;

			// Save this id at backend
			await this.$store.dispatch("researchPaper/setTemplateUUID", {
				id: id,
				uuid,
			});

			return uuid;
		},

		decideLevelOrSubLevel(requested_pos) {
			// if no chapters are available then return the level 1
			if (!requested_pos || !this.suitableChapters?.length) return 1;

			// Find the requested pos index
			let next_pos;
			let requested_pos_index = findElIndex(
				this.suitableChapters,
				this.suitableKey,
				requested_pos,
			);

			if (requested_pos_index != -1) {
				// Find next item's position
				next_pos = this.suitableChapters[requested_pos_index + 1]?.[
					this.suitableKey
				];
			}

			// Calculate the final position
			let final = next_pos
				? Math.ceil((requested_pos + next_pos) / 2)
				: requested_pos + 10;

			// Check if the final position is already occupied
			if (findFromArray(this.suitableChapters, this.suitableKey, final)) {
				throw new Error("app.ntfy.err.chapter_cannot_be_added", {
					cause: "werCustom",
				});
			}

			return final;
		},

		async submit() {
			try {
				// Validate the form
				if (!this.$refs.form.validate()) return;

				this.loading = true;

				// sanitize the form's input before submit
				let payload = this.form;
				payload.title = this.$xss.sanitize(this.form.title);
				payload.content = this.$xss.sanitize(this.form.content);
				// If it's for add new chapter then only apply the check
				if (this.toAddChapter || this.toAddSubChapter) {
					payload[this.suitableKey] = this.decideLevelOrSubLevel(this.after);
				}

				// first sanitize then update or create chapter or add subchapter
				let response = await this.$store.dispatch(
					`researchPaper/${this.toEditChapter ? "editChapter" : "addChapter"}`,
					payload,
				);

				// Generate uuid for this newly created chapter/subchapter
				if (this.toAddChapter || this.toAddSubChapter) {
					let uuid = await this.setUuidForCreatedChapter(response.id);

					// create pad for this newly added chapter
					await this.$store.dispatch("etherpad/createPad", {
						pad_id: uuid,
						text: "",
					});
				}

				// Update state
				await this.$store.dispatch("researchPaper/template");

				// Reset form
				this.$refs.form.reset();

				// Close the dialog
				this.$emit("close");
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.loading = false;
			}
		},
	},
};
</script>
