export const prepareReportDataMixin = {
	data() {
		return {
			report_data: [],
		};
	},

	methods: {
		async prepareChapterHtml(chapter_id) {
			if (chapter_id && chapter_id.includes("-")) {
				let response = await this.$store.dispatch("etherpad/getHTML", {
					pad_id: chapter_id,
				});

				return response ? response.html : null;
			}
			return null;
		},

		async prepareChapterText(chapter_id) {
			if (chapter_id && chapter_id.includes("-")) {
				let response = await this.$store.dispatch("etherpad/getText", {
					pad_id: chapter_id,
				});

				return response ? response.text : null;
			}
			return null;
		},

		async prepareUsedReferences() {
			var response = await this.$store.dispatch("etherpad/getUsedReferences", {
				user_id: this.userProfile.id,
			});
			return response;
		},

		async prepareReportData(content_type = "html") {
			try {
				this.$loader.start();

				let filtered_data = this.toc.filter(
					(item) => ![0, -2].includes(item.id),
				);

				for (const [cIndex, chapter] of filtered_data.entries()) {
					chapter.num = `${cIndex + 1}.`;
					chapter.chapter_type = chapter.chapter_type;
					// Don't fetch pad content for bibliography
					if (chapter.chapter_type == "bib") {
						chapter.references = await this.prepareUsedReferences();
					} else {
						chapter.html = await this.prepareChapterHtml(chapter.uuid);
						chapter.text = await this.prepareChapterText(chapter.uuid);
					}
					// If there is any children of chapters
					chapter.children = chapter.children.filter((item) => item.id != -1);
					if (chapter.children.length) {
						for (const [sIndex, subChapter] of chapter.children.entries()) {
							subChapter.num = `${cIndex + 1}.${sIndex + 1}`;
							subChapter.html = await this.prepareChapterHtml(subChapter.uuid);
							subChapter.text = await this.prepareChapterText(subChapter.uuid);
						}
					}
					this.report_data.push(chapter);
				}

				await this.onAfterReportPrepare();
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},
};
