<template>
	<section>
		<h2 v-if="!chapter.sub_level">{{ chapter.num }} {{ chapter.title }}</h2>
		<h3 v-else>
			{{ chapter.num }}&ensp;
			<u> {{ chapter.title }} </u>
		</h3>
		<p v-if="chapter.num != 1" v-html="chapter.html"></p>
	</section>
</template>

<script>
export default {
	name: "ResearchPaperChapterPreview",

	props: {
		chapter: {
			required: true,
		},
	},
};
</script>
