<template>
	<div>
		<v-expansion-panels v-model="checklist" flat>
			<v-expansion-panel>
				<v-expansion-panel-header class="px-2">
					<template v-slot:default>
						<div>
							<v-icon>
								{{
									checklist == null
										? $vuetify.icons.values[
												$vuetify.rtl ? "menu_left" : "menu_right"
										  ]
										: $vuetify.icons.values.menu_down
								}}
							</v-icon>
							<span class="headline wr_blue_1--text">
								{{ $t("app.checklist") }}
							</span>
						</div>
					</template>
					<template v-slot:actions>
						<span></span>
					</template>
				</v-expansion-panel-header>

				<v-expansion-panel-content>
					<v-card class="pl-2">
						<v-row>
							<v-col v-for="tip in content('checklist')" :key="tip.id">
								<v-checkbox :label="tip.tip" color="greenAccent2"></v-checkbox>
							</v-col>
						</v-row>
					</v-card>
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>
		<div id="checklist"></div>
	</div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
	name: "ResearchPaperChecklist",

	data() {
		return {
			checklist: null,
		};
	},

	computed: {
		...mapGetters({
			content: "researchPaper/getHelpContent",
		}),
	},
};
</script>
