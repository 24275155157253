var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"value":_vm.dialog,"scrollable":"","max-width":"550px","persistent":""}},[_c('AppDialog',{attrs:{"heading":{
			label: _vm.toEditChapter
				? _vm.$t('app.edit_chapter')
				: _vm.toAddChapter
				? _vm.$t('app.add_chapter')
				: _vm.$t('app.add_subchapter'),
		},"action":{
			label: 'app.save',
			event: 'save',
		}},on:{"close":function($event){return _vm.$emit('close')},"save":function($event){return _vm.submit()}},scopedSlots:_vm._u([{key:"dialog-content",fn:function(){return [(_vm.toAddSubChapter)?_c('div',[_c('b',[_vm._v(_vm._s(_vm.prop_chapter.parent.title))]),_vm._v(" > "+_vm._s(_vm.$t("app.new_subchapter"))+" ")]):_vm._e(),_c('v-form',{ref:"form",staticClass:"mt-9"},[_c('v-text-field',{attrs:{"rules":[_vm.rules.required],"color":"greenAccent2","background-color":"white","outlined":"","label":_vm.$t('app.title')},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}}),_c('v-textarea',{attrs:{"rules":[_vm.rules.required],"color":"greenAccent2","background-color":"white","outlined":"","label":_vm.$t('app.write_sentences_about_content')},model:{value:(_vm.form.content),callback:function ($$v) {_vm.$set(_vm.form, "content", $$v)},expression:"form.content"}}),(_vm.toAddChapter || _vm.toAddSubChapter)?_c('v-row',[_c('v-col',[(_vm.suitableChapters && _vm.suitableChapters.length)?_c('v-select',{attrs:{"rules":[_vm.rules.required],"items":_vm.suitableChapters,"item-text":"title","item-value":_vm.toAddChapter ? 'level' : 'sub_level',"placeholder":_vm.$t('app.move_after'),"outlined":"","dense":"","color":"greenAccent2","background-color":"white"},model:{value:(_vm.after),callback:function ($$v) {_vm.after=$$v},expression:"after"}}):_vm._e()],1)],1):_vm._e()],1)]},proxy:true}]),model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }