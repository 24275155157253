<template>
	<div v-if="queries.length">
		<ul :class="['the-header-instructions-list-wr', { 'pr-6': $vuetify.rtl }]">
			<li
				v-for="(query, index) in queries"
				:key="index"
				:class="['pt-2', index !== 0 ? 'mt-5' : '']"
			>
				<div class="subtitle-1 black--text font-weight-bold">
					{{ query.question }}
				</div>
				<div class="greyDarken4--text subtitle-2 white-space-pre-line-wr">
					{{ query.answer }}
				</div>
			</li>
		</ul>
	</div>
</template>

<script>
import { isArrayHavingItem } from "@/utils/helpers";

export default {
	name: "ResearchPaperPreviousTasksText",

	data() {
		return {
			queries: [],
		};
	},

	mounted() {
		this.getRequiredQuestionnaires();
	},

	methods: {
		async getRequiredQuestionnaires() {
			try {
				this.$loader.start();

				// Get "DEFINES[q10]" questionnaire
				await this.prepareQueries(this.$defines.QUESTIONNAIRE.q10, [1, 2]);

				// Get "DEFINES[q20]" questionnaire
				await this.prepareQueries(this.$defines.QUESTIONNAIRE.q20, [2]);

				// Get "DEFINES[q30]" questionnaire
				await this.prepareQueries(this.$defines.QUESTIONNAIRE.q30, [1, 2]);
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async prepareQueries(questionnaire_name, orders) {
			let response = await this.$store.dispatch("questionnaire/get", {
				questionnaire_name,
			});

			orders.forEach((order) => {
				let result = response.answers.find(
					(answer) => answer.question.order === order,
				);
				if (isArrayHavingItem(["open", "large_open"], result.question.type)) {
					let obj = {
						question: result.question.name,
						answer: result.formated_answer,
					};
					this.queries.push(obj);
				}
			});
		},
	},
};
</script>
