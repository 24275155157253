<template>
	<v-dialog :value="dialog" scrollable max-width="500px" persistent>
		<AppDialog
			:heading="{
				label: $t('app.word_count'),
			}"
			:action="{
				label: 'app.okay',
				event: 'close',
			}"
			@close="$emit('close')"
		>
			<template v-slot:dialog-content>
				<v-card flat>
					<v-card-text class="subtitle-1 black--text">
						<v-row>
							<v-col sm="9">{{ $t("app.words") | capitalize }}</v-col>
							<v-col v-if="words" align="end">{{ words }}</v-col>
							<v-col v-else><Loading scale="0.5"></Loading></v-col>
						</v-row>
						<v-divider class="my-3"></v-divider>

						<v-row>
							<v-col sm="9">{{ $t("app.characters") }}</v-col>
							<v-col v-if="chars" align="end">{{ chars }}</v-col>
							<v-col v-else><Loading scale="0.5"></Loading></v-col>
						</v-row>
						<v-divider class="my-3"></v-divider>

						<v-row>
							<v-col sm="9">{{ $t("app.chars_excluding_spaces") }}</v-col>
							<v-col v-if="chars" align="end">{{
								chars_excluding_spaces
							}}</v-col>
							<v-col v-else><Loading scale="0.5"></Loading></v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</template>
		</AppDialog>
	</v-dialog>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { flatArrayOfObjects } from "@/utils/helpers";
import { prepareReportDataMixin } from "@/mixins/researchPaper/prepareReportDataMixin.js";
import { researchPaperTOCMixin } from "@/mixins/researchPaper/tocMixin.js";
import AppDialog from "@/components/ui/AppDialog.vue";
import Loading from "@/components/plugins/Loading.vue";

export default {
	name: "WordCountDialog",

	mixins: [researchPaperTOCMixin, prepareReportDataMixin],

	props: {
		dialog: {
			required: true,
			type: Boolean,
		},
	},

	data() {
		return {
			words: null,
			chars: null,
			chars_excluding_spaces: null,
		};
	},

	components: {
		AppDialog,
		Loading,
	},

	computed: {
		...mapState({
			researchTemplate: (state) => state.researchPaper.template,
			toolsResearchPaperTemplate: (state) => state.toolsResearchPaper.template,
		}),

		...mapGetters({
			userProfile: "user/getProfile",
		}),

		template() {
			return this.isCollaborationRoute
				? this.toolsResearchPaperTemplate
				: this.researchTemplate;
		},
	},

	mounted() {
		this.prepareReportData();
	},

	methods: {
		async countWordsAndChars(payload, type = "text") {
			// If html to be count
			if (type == "text") {
				return {
					words: payload.trim().split(/\s+/).length,
					chars: payload.trim().length,
					chars_excluding_spaces: payload.trim().replace(/\s/g, "").length,
				};
			}

			// If bibliography references to be count
			let words = 0;
			let chars = 0;
			let chars_excluding_spaces = 0;

			for (const item of payload) {
				words += item.text.trim().split(/\s+/).length;
				chars += item.text.length;
				chars_excluding_spaces += item.text.trim().replace(/\s/g, "").length;
			}
			return { words, chars, chars_excluding_spaces };
		},

		async onAfterReportPrepare() {
			let arr = flatArrayOfObjects(this.report_data, "children");
			let words = 0;
			let chars = 0;
			let chars_excluding_spaces = 0;

			for (const item of arr) {
				var response;
				// If item has references property (Ex. Bibliography)
				if (item.references && item.references.length) {
					response = await this.countWordsAndChars(
						item.references,
						"references",
					);
				}
				// If item has text property
				if (item.text) {
					response = await this.countWordsAndChars(item.text);
				}
				// Calculate params
				words += response.words;
				chars += response.chars;
				chars_excluding_spaces += response.chars_excluding_spaces;
			}
			// Assign to local variables
			this.words = words;
			this.chars = chars;
			this.chars_excluding_spaces = chars_excluding_spaces;
		},
	},
};
</script>

<style scoped>
.vue-loaders.ball-pulse-sync {
	padding: 0px !important;
}
</style>
