<template>
	<div>
		<ResearchPaperLayout
			:section_component="section_component"
			:chapter_id="chapter_id"
		>
			<!-- Toolbar -->
			<template v-slot:toolbar>
				<v-toolbar>
					<!-- Help menu -->
					<AppButton
						v-for="item in $defines.RESEARCH_PAPER_HELP_MENU"
						:key="`help-menu-${item.id}`"
						:label="item.title"
						color="black"
						:text="true"
						@click="selected_menu_id = item.id"
					></AppButton>

					<v-spacer></v-spacer>

					<!-- Content Menu -->
					<!-- Download pdf -->
					<AppButton
						label="app.download_pdf"
						color="black"
						:text="true"
						@click="(show_preview = true), (format = 'pdf')"
					></AppButton>

					<!-- Download word -->
					<AppButton
						label="app.download_word"
						color="black"
						:text="true"
						@click="(show_preview = true), (format = 'word')"
					></AppButton>

					<!-- Word count -->
					<AppButton
						label="app.word_count"
						color="black"
						:text="true"
						@click="word_dialog = true"
					></AppButton>

					<!-- Checklist -->
					<!-- <AppButton
						label="app.checklist"
						color="black"
						:text="true"
						@click="goToScroll('#checklist')"
					></AppButton> -->

					<!-- Dialog -->
					<!-- <AppButton
						label="app.dialog"
						color="black"
						:text="true"
						@click="goToScroll('#suggestion')"
					></AppButton> -->
				</v-toolbar>
			</template>

			<!-- Help menu -->
			<template v-slot:help-content>
				<ResearchHelpContent
					v-if="selected_menu_id"
					:chapter_id="chapter_id"
					:menu_id="selected_menu_id"
				/>
			</template>

			<!-- checklist -->
			<!-- <template v-slot:checklist>
				<Checklist />
			</template> -->

			<!-- mentor suggestions -->
			<!-- <template v-slot:mentor-suggestion>
				<MentorSuggestion />
			</template> -->
		</ResearchPaperLayout>

		<WordCountDialog
			v-if="word_dialog"
			:dialog="word_dialog"
			@close="word_dialog = false"
		></WordCountDialog>

		<ResearchPaperPreview
			v-if="show_preview"
			:format="format"
			@close="show_preview = false"
		></ResearchPaperPreview>
	</div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import * as featureComponents from "@/components/research/modules/write/stages/researchPaper/features/index.js";
import ResearchHelpContent from "@/components/research/modules/write/stages/researchPaper/help/Content";
import ResearchPaperLayout from "@/components/research/modules/write/stages/researchPaper/Layout.vue";
import WordCountDialog from "@/components/research/modules/write/stages/researchPaper/WordCountDialog.vue";
import ResearchPaperPreview from "@/components/research/modules/write/stages/researchPaper/preview/Template.vue";

export default {
	name: "ResearchPaperWritingTemplate",

	props: {
		section_component: {
			required: true,
			type: String,
		},
		chapter_id: {
			required: true,
			type: Number,
		},
	},

	data() {
		return {
			word_dialog: false,
			show_preview: false,
			format: null,
			selected_menu_id: null,
		};
	},

	components: {
		ResearchHelpContent,
		...featureComponents,
		ResearchPaperLayout,
		WordCountDialog,
		ResearchPaperPreview,
	},

	computed: {
		...mapState({
			current_tab: (state) => state.researchPaper.current_tab,
		}),

		...mapGetters({
			userLang: "user/getLanguage",
		}),
	},
};
</script>
