<template>
	<v-card max-height="1" style="overflow:scroll">
		<section id="printContainer" ref="printContainer">
			<!-- Intro page -->
			<IntroPage
				v-if="research_question"
				:styleObj="contentPadding"
				:research_question="research_question"
			></IntroPage>
			<section style="page-break-before:always">&nbsp;</section>

			<section v-if="report_data.length">
				<!-- Toc Page -->
				<TableOfContentsPage
					:prop_data="report_data"
					:styleObj="contentPadding"
				></TableOfContentsPage>
				<section style="page-break-before:always">&nbsp;</section>

				<!-- Chapters Page -->
				<section id="pads-content">
					<template v-for="(chapter, chapIndex) in report_data">
						<section
							:key="chapter.id"
							:style="contentPadding"
							:id="`pad-${chapter.id}`"
						>
							<!-- Bibliography chapter -->
							<BibliographyPage
								v-if="chapter.chapter_type == 'bib'"
								:chapter="chapter"
							></BibliographyPage>
							<!-- Other chapters -->
							<template v-else>
								<ChapterPage :chapter="chapter"></ChapterPage>
								<!-- Sub Chapters -->
								<section v-if="chapter.children && chapter.children.length">
									<section
										:style="{ marginTop: '30px' }"
										v-for="subChapter in chapter.children"
										:key="subChapter.id"
									>
										<ChapterPage :chapter="subChapter"></ChapterPage>
									</section>
								</section>
							</template>

							<!-- A page break -->
							<section
								v-if="chapIndex < report_data.length - 1"
								style="page-break-before:always"
							>
								&nbsp;
							</section>
						</section>
					</template>
				</section>
			</section>
		</section>
	</v-card>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { researchPaperTOCMixin } from "@/mixins/researchPaper/tocMixin.js";
import { findFromArray } from "@/utils/helpers";
import ChapterPage from "./Chapter";
import IntroPage from "./IntroPage";
import TableOfContentsPage from "./TableOfContents";
import BibliographyPage from "./Bibliography";
import { questionnaireHelper } from "@/utils/helpers/questionnaire";
import { prepareReportDataMixin } from "@/mixins/researchPaper/prepareReportDataMixin.js";

export default {
	name: "ResearchPaperTemplatePreview",

	mixins: [researchPaperTOCMixin, prepareReportDataMixin],

	props: {
		format: {
			required: true,
		},
	},

	data() {
		return {
			research_question: null,
		};
	},

	components: {
		ChapterPage,
		IntroPage,
		TableOfContentsPage,
		BibliographyPage,
	},

	mounted() {
		this.init();
	},

	computed: {
		...mapState({
			researchTemplate: (state) => state.researchPaper.template,
			toolsResearchPaperTemplate: (state) => state.toolsResearchPaper.template,
		}),

		...mapGetters({
			isMentor: "user/isMentor",
			userProfile: "user/getProfile",
			userLang: "user/getLanguage",
		}),

		template() {
			return this.isCollaborationRoute
				? this.toolsResearchPaperTemplate
				: this.researchTemplate;
		},

		contentPadding() {
			return {
				padding: "60px",
				lineHeight: "35px",
				fontSize: "15pt",
				textAlign: "justify",
				direction: ["ar", "he"].includes(this.userLang) ? "rtl" : "ltr",
			};
		},
	},

	methods: {
		async getResearchQuestion() {
			let res = await questionnaireHelper.getResearchQuestion();
			return res ? res.answer : res;
		},

		async init() {
			this.research_question = await this.getResearchQuestion();

			await this.prepareReportData();
		},

		onAfterReportPrepare() {
			this.format == "pdf" ? this.exportToPDF() : this.exportToWord();
			this.$emit("close");
		},

		setWordsCountHTML() {
			let e = document.getElementById("pads-content");

			if (e) {
				let total_words = e.innerText.trim().split(/\s+/).length;
				let el = document.getElementById("total-words");
				if (el) {
					el.innerText = total_words;
				}
			}
		},

		onAfterPrintOrAfterCancel(window_param) {
			var mediaQueryList = window_param.matchMedia("print");
			if (mediaQueryList.media) {
				this.report_data = [];
				this.$emit("close");
			}
		},

		createPrintFrame() {
			var printFrame = document.createElement("iframe");
			printFrame.name = "printFrame";
			printFrame.style.position = "absolute";
			printFrame.style.top = "-1000000px";
			document.body.appendChild(printFrame);
			return printFrame;
		},

		fillContentInPrintFrame(printFrame, contents) {
			var printDoc = printFrame.contentWindow
				? printFrame.contentWindow
				: printFrame.contentDocument.document
				? printFrame.contentDocument.document
				: printFrame.contentDocument;
			printDoc.document.open();
			printDoc.document.write(
				"<html><head><title>DIV Contents</title></head><body>" +
					contents +
					"</body></html>",
			);
			printDoc.document.close();
			return printDoc;
		},

		exportToPDF() {
			try {
				// Count total words of report and set html
				this.setWordsCountHTML();
				// Now, get the html to be print
				var contents = document.getElementById("printContainer").innerHTML;
				// Create print frame
				var printFrame = this.createPrintFrame();
				// Fill content in print frame
				var printDoc = this.fillContentInPrintFrame(printFrame, contents);
				// open window after 500ms
				setTimeout(() => {
					// Set focus on iframe window
					window.frames["printFrame"].focus();
					// Print content inside iframe window
					window.frames["printFrame"].print();
					// Catch print or cancel event
					this.onAfterPrintOrAfterCancel(window.frames["printFrame"]);
					// Remove iframe from DOM
					document.body.removeChild(printFrame);
				}, 500);
			} catch (error) {
				throw error;
			}
		},

		async exportToWord(filename = "") {
			// Count total words of report and set html
			this.setWordsCountHTML();

			var header =
				"<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML to Word Document with JavaScript</title></head><body>";

			var footer = "</body></html>";

			var html = header + this.$refs.printContainer.innerHTML + footer;

			let response = await this.$store.dispatch("process/convertHTMLToWord", {
				rtl: this.userLang != "en",
				source: html,
			});

			if (response && response.doc_url) {
				var url = `${this.$environments.base_api_url}${response.doc_url}`;
			}

			var blob = new Blob(["\ufeff", html], {
				type: "application/msword",
			});

			// Specify file name
			filename = filename ? filename + ".docx" : `${Date.now()}.docx`;

			// Create download link element
			var downloadLink = document.createElement("a");

			document.body.appendChild(downloadLink);

			if (navigator.msSaveOrOpenBlob) {
				navigator.msSaveOrOpenBlob(blob, filename);
			} else {
				// Create a link to the file
				downloadLink.href = url;

				// Setting the file name
				downloadLink.download = filename;

				//triggering the function
				downloadLink.click();
			}

			document.body.removeChild(downloadLink);
		},
	},
};
</script>
