<template>
	<v-card outlined tile v-if="selected_item" class="greyLighten4">
		<v-card-text>
			<v-row no-gutters>
				<v-col>
					<PreviousTasksText
						v-if="selected_item.dialog_model === 'previous_task'"
					></PreviousTasksText>

					<div v-else class="black--text">
						<ul
							v-if="content && content.length"
							class="the-header-instructions-list-wr custom-ul-wr"
						>
							<li v-for="item in content" :key="item.id" v-html="item.tip"></li>
						</ul>
					</div>
				</v-col>
				<v-col sm="1" class="text-end">
					<v-icon color="error" @click="selected_item = null"
						>$vuetify.icons.values.close</v-icon
					>
				</v-col>
			</v-row>
		</v-card-text>
	</v-card>
</template>
<script>
import { mapState } from "vuex";
import PreviousTasksText from "@/components/research/modules/write/stages/researchPaper/help/PreviousTasksText.vue";
import { findFromArray } from "@/utils/helpers";

export default {
	name: "ResearchPaperHelpContent",

	props: {
		chapter_id: {
			required: true,
			type: Number,
		},
		menu_id: {
			required: true,
		},
	},

	data() {
		return {
			selected_item: null,
			content: null,
		};
	},

	components: {
		PreviousTasksText,
	},

	watch: {
		menu_id: {
			handler(value) {
				// Clear the prvious content
				this.content = null;

				// Now fetch the requested content.
				this.selected_item = findFromArray(
					this.$defines.RESEARCH_PAPER_HELP_MENU,
					"id",
					this.menu_id,
				);
				this.getContent(this.selected_item.tip_type);
			},
			immediate: true, // This ensures the watcher is triggered upon creation
		},
	},

	methods: {
		async getContent(tip_type) {
			try {
				this.$loader.start();

				let response = await this.$store.dispatch(
					"researchPaper/getHelpContent",
					{
						id: this.chapter_id,
						tip_type,
					},
				);

				if (response && response.length) {
					this.content = response.filter((item) => item.tip_type === tip_type);
				}
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},
};
</script>
